import React, { useState } from 'react';
import { Formik, FormikErrors, FormikHelpers } from 'formik';

import { convertRequestErrorToMap, Nullable } from '@tager/web-core';

import * as S from '@/components/modals/DetailedCarModal/components/Application/common.styles';
import { BASE_APPLICATION_TITLE } from '@/components/modals/DetailedCarModal/components/Application/common.constans';
import { BaseComponentProps } from '@/components/modals/DetailedCarModal/components/Application/common.types';
import Request from '@/components/modals/DetailedCarModal/components/Application/components/TestDriveOffline/steps/Request';
import {
  handleValidateTestDriveOfflineForm,
  hasErrors,
} from '@/components/modals/DetailedCarModal/components/Application/common.helpers';
import { CarPreviewWrapper } from '@/components/modals/DetailedCarModal/components/Application/common.styles';
import CarPreview from '@/components/CarPreview';
import { googleEvent } from '@/utils/events';
import { DetailedCarTestDriveOfflineParams } from '@/services/leads/typings';
import useSettingItem from '@/hooks/useSettingItem';
import { submitDetailedCarTestDriveOffline } from '@/services/leads/leads-service';
import { TestDriveData } from '@/typings/model';

import { INITIAL_FORM_VALUES } from './TestDriveOffline.constans';

function TestDriveOffline({
  type,
  typeState,
  car,
  onSuccess,
}: BaseComponentProps) {
  useState<Nullable<TestDriveData>>(null);
  const testDriveGoogleEvent = useSettingItem(
    typeState === 'new'
      ? 'DETAILED_CAR_NEW_TEST_DRIVE_GOOGLE_EVENT'
      : 'DETAILED_CAR_AMP_TEST_DRIVE_GOOGLE_EVENT'
  );

  async function onSubmit(
    values: DetailedCarTestDriveOfflineParams,
    formikHelper: FormikHelpers<DetailedCarTestDriveOfflineParams>
  ) {
    formikHelper.setSubmitting(true);

    const sendingData: DetailedCarTestDriveOfflineParams = {
      id: car.id,
      type: typeState,
      dealer: values.dealer,
      name: values.name,
      phone: values.phone,
    };
    try {
      await submitDetailedCarTestDriveOffline(sendingData).then(() => {
        if (testDriveGoogleEvent) {
          googleEvent(testDriveGoogleEvent, car.googleEventParams);
        }
      });

      onSuccess();
    } catch (error: any) {
      const errorMap = convertRequestErrorToMap(
        error
      ) as FormikErrors<DetailedCarTestDriveOfflineParams>;
      formikHelper.setErrors(errorMap);
      console.error(error);
    }

    formikHelper.setSubmitting(false);
  }

  return (
    <S.Component>
      <S.StepTitle>{BASE_APPLICATION_TITLE[typeState][type]}</S.StepTitle>

      <CarPreviewWrapper>
        <CarPreview
          image={car.image}
          name={car.name}
          price={car.price}
          currency="BYN"
          body={car.bodyType}
          carType={typeState}
        />
      </CarPreviewWrapper>

      <Formik<DetailedCarTestDriveOfflineParams>
        onSubmit={onSubmit}
        validateOnChange
        validate={handleValidateTestDriveOfflineForm}
        initialValues={INITIAL_FORM_VALUES}
      >
        {({
          handleSubmit,
          values,
          errors,
          handleChange,
          touched,
          handleBlur,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <>
              <S.Form onSubmit={handleSubmit}>
                <Request
                  errors={errors}
                  values={values}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  dealers={car.dealers ?? []}
                  isSubmitting={isSubmitting}
                  hasErrors={hasErrors(
                    handleValidateTestDriveOfflineForm(values)
                  )}
                  setFieldValue={setFieldValue}
                />
              </S.Form>
            </>
          );
        }}
      </Formik>
    </S.Component>
  );
}

export default TestDriveOffline;
